

































































































































































































































































































































































































































































































































































































































































































































































.largeScreen {
  // tr {
  //   pointer-events: none;
  // }
  .el-table tbody tr:hover > td {
    background-color: transparent !important;
  }
  .el-table,
  .el-table__expanded-cell {
    background: transparent;
  }
  .el-table .cell {
    color: #cecece;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /*更改表格颜色*/
  .double {
    background: #141e38 !important;
  }
  .single {
    background: #03051b !important;
  }
  .el-dialog {
    background: transparent;
  }
  .el-dialog__header {
    display: none;
  }
  .dialog2 {
    .el-dialog__body {
      background-image: url("../../assets/dialogbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 0;
    }
  }

  .studentspan {
    width: 4rem;
  }
  .dialogpdf {
    .el-dialog__body {
      background-image: url("../../assets/dialogbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
     padding: 40px 20px 34px;
    }
  }
}
